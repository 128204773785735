import { graphql, useStaticQuery } from 'gatsby';

const useAnimatedTrustbar = () => {
  const { contentfulImage } = useStaticQuery(graphql`
    query {
      contentfulImage(internalName: { eq: "Animated Trust Bar" }) {
        ...WebsiteImage
      }
    }
  `);

  return contentfulImage;
};

export default useAnimatedTrustbar;
