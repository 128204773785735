import React from 'react';
import { graphql } from 'gatsby';
import DemoHeroSection from 'components/Page/Demo/DemoHeroSection';
import DemoHeroSection1 from 'components/Page/Demo/DemoHeroSection/DemoHeroSection1';
import SEO, { createSEO } from 'components/Core/SEO';
import WebsiteLayout from 'layouts/websiteLayout';

const DemoTemplate = (props) => {
  const { contentfulDemoPage } = props.data;
  const initEmailValue = props.location.state ? props.location.state.valuefromSubscribeForm : null;
  const {
    backgroundColor,
    title,
    description,
    advantages,
    formTitle,
    formButtonTitle,
    form,
    formCardImg,
    trustBar,
  } = contentfulDemoPage;

  const seo = createSEO(contentfulDemoPage.seo, props);

  const demoForm = {
    ...form,
    formTitle,
    formButtonTitle,
  };

  const leftSide = {
    title: trustBar?.title,
    backgroundImage: formCardImg,
  };

  return (
    <WebsiteLayout header={{}} footer={{}} id="demo-page">
      <SEO {...seo} />
      {backgroundColor === 'White' ? (
        <DemoHeroSection
          footer={false}
          title={title}
          description={description?.description}
          advantages={advantages}
          trustedSection={trustBar}
          demoForm={demoForm}
          initEmailValue={initEmailValue}
        />
      ) : (
        <DemoHeroSection1
          backgroundColor={backgroundColor}
          footer={false}
          title={title}
          description={description?.description}
          leftSide={leftSide}
          demoForm={demoForm}
          trustedSection={trustBar}
          initEmailValue={initEmailValue}
        />
      )}
    </WebsiteLayout>
  );
};

export default DemoTemplate;

export const DemoTemplatesPageQuery = graphql`
  query DemoPageQuery($slug: String!) {
    contentfulDemoPage(seo: { pageUrl: { eq: $slug } }) {
      updatedAt
      seo {
        ...Seo
      }
      id
      socialSharingImage {
        ... on ContentfulImage {
          alt
          image {
            file {
              url
            }
          }
        }
      }
      canonicalUrl
      backgroundColor
      title
      description {
        description
      }
      advantages
      formTitle
      formButtonTitle
      form {
        ...InlineMarketingForm
      }
      formCardImg {
        ...Image
      }
      trustBar {
        id
        title
        imgs {
          ...Image
        }
        backgroundColor
      }
    }
  }
`;
