import styled from 'styled-components';

export const SectionDemoHero = styled.div`
  background: linear-gradient(90deg, transparent 60%, ' + ' #f6f8fa ' + ' 60%);

  .section-demo-hero--container {
    display: flex;
    align-items: normal;
    max-width: 1278px;
    padding: 36px 28px;
    margin: 0 auto;

    .section-demo-hero--left-side {
      width: 50%;

      .section-demo-hero--logo-wrapper {
        a {
          & > svg {
            width: 234px;
            height: 41px;
          }
        }
      }

      .section-demo-hero--text-content {
        padding-right: 100px;
        margin-top: 55px;

        .section-demo-hero--description {
          font-size: var(--font-size-h5);
        }

        .section-demo-hero--advantage-container {
          .section-demo-hero--advantage-item {
            display: flex;
            padding: 5px 0;

            svg {
              margin-top: 4px;
            }

            .section-demo-hero--advantage-item-title {
              margin: 0 0 5px 15px;
            }
          }
        }
      }

      .section-demo-hero--mobile-form-content {
        display: none;
      }
    }

    .section-demo-hero--right-side {
      width: 50%;
      padding: 91px 0 0 64px;
    }
  }

  
  .section-demo-hero--signup-form {
      /* max-width: 470px; */
      .signup-form--footer {
        p {
          color: var(--white);
        }
        a {
          color: var(--white);
          text-decoration: underline;
        }
      }
    }
  }

  .section-demo-hero--separator {
    border-top: 1px solid var(--grey-3);
    margin-top: 56px;
    margin-bottom: 43px;
  }

  .section-demo-hero--animated-trustbar-container {
    height: 70px;
    .section-demo-hero--animated-trustbar {
      position: relative;
      margin: 0;
      width: 100%;
      height: 100%;
      animation: ani 60s linear infinite;

      @media (max-width: 756px) {
        animation: ani 15s linear infinite;
      }
    }
    @keyframes ani {
      0% {
        background-position: 100vw 0;
      }
      100% {
        background-position: 0 0;
      }
    }

    .section-demo-hero--animated-trustbar::before,
    .section-demo-hero--animated-trustbar::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(90deg, var(--white), transparent);
      width: 50px;
    }

    .section-demo-hero--animated-trustbar::after {
      left: auto;
      right: 0;
      background: linear-gradient(-90deg, var(--white), transparent);
    }
  }

  .section-demo-hero--trusted-logo-content {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .section-demo-hero--trusted-logo-item {
      margin-right: 30px;
      text-align: center;
      width: 120px;

      img {
        max-width: 100%;
      }
    }
  }

  .section-demo-hero--common-container {
    display: none;
    padding: 0px 28px 56px;

    .section-demo-hero--separator {
      max-width: 1278px;
      margin-top: 28px;
      margin-left: 28px;
      margin-right: 28px;
    }

    .section-demo-hero--trusted-content {
      .section-demo-hero--trusted-title {
        text-align: center;
      }
    }

    .section-demo-hero--trusted-logo-content {
      padding: 0 56px;
      justify-content: center;
    }
  }

  @media (max-width: 991px) {
    .section-demo-hero--container {
      .section-demo-hero--left-side {
        .section-demo-hero--logo-wrapper {
          a {
            & > svg {
              width: 161px;
              height: 28px;
            }
          }
        }

        .section-demo-hero--text-content {
          padding-right: 0;
          margin-top: 55px;

          .section-demo-hero--description {
            font-size: var(--font-size-16);
          }

          .section-demo-hero--advantage-container {
            .section-demo-hero--advantage-item {
              svg {
                width: 19px;
                margin-top: 0px;
              }
            }
          }
        }

        .section-demo-hero--mobile-form-content {
          display: none;
          margin: 50px 0;

          .section-demo-hero--signup-form {
            margin: 0 auto;
            max-width: 570px;
          }
        }
      }

      .section-demo-hero--right-side {
        padding-left: 30px;
      }
    }

    .section-demo-hero--container {
      .section-demo-hero--separator,
      .section-demo-hero--trusted-content,
      .section-demo-hero--trusted-logo-content {
        display: none;
      }
      .section-demo-hero--animated-trustbar-container {
        display: none;
      }
    }

    .section-demo-hero--common-container {
      display: block;
      padding: 0 20px 30px;

      .section-demo-hero--separator {
        margin: 28px 0 32px;
      }

      .section-demo-hero--trusted-content {
        .section-demo-hero--trusted-title {
          font-size: var(--font-size-22);
          text-align: left;
          margin-bottom: 24px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-demo-hero--container {
      .section-demo-hero--left-side {
        width: 44%;
      }

      .section-demo-hero--right-side {
        width: 56%;
      }
    }
  }

  @media (max-width: 695px) {
    background: transparent;

    .section-demo-hero--container {
      padding: 28px 20px 0;
      flex-direction: column;

      .section-demo-hero--left-side {
        width: 100%;

        .section-demo-hero--trusted-logo-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          flex-direction: row;
          margin-bottom: 50px;

          .section-demo-hero--trusted-logo-item {
            margin-right: 0;
            margin-bottom: 20px;
            text-align: left;
            margin: 48px 15px 0;
          }
        }
      }

      .section-demo-hero--right-side {
        width: 100%;
        padding: 32px 0 0;
      }

      .section-demo-hero--signup-form {
        .signup-form--form .signup-form--step1 .signup-form--title {
          padding: 0 0 24px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-demo-hero--signup-form {
      box-shadow: rgb(11 26 33 / 15%) 0px 30px 60px !important;
    }
  }
`;

export const SectionDemoHero1 = styled.div`
  width: 100%;
  height: 100%;

  .section-demo-hero--wrapper {
    width: 100%;
    height: 890px;
    background-color: ${(props) => `var(${props.backgroundColor})`};
  }

  .section-demo-hero--container {
    max-width: 1253px;
    padding: 34px 28px 80px;
    margin: 0 auto;

    .section-demo-hero--top-side {
      .section-demo-hero--logo-wrapper {
        a {
          & > svg {
            width: 234px;
          }
        }
      }

      .section-demo-hero--text-content {
        margin-top: 90px;
        text-align: center;

        .section-demo-hero--title {
          text-align: center;
          color: var(--white);
        }

        .section-demo-hero--description {
          font-size: var(--font-size-25);
          line-height: var(--line-height-150);
          text-align: center;
          letter-spacing: var(--letter-spacing--0-01);
          color: var(--white);
          max-width: 850px;
          margin: 0 auto;
          margin-top: 30px;
        }
      }

      .section-demo-hero--form-section {
        display: flex;
        margin-top: 50px;
        box-shadow: 0px 30px 60px rgba(11, 26, 33, 0.15);

        .section-demo-hero--form-left-side {
          width: 50%;
          background: ${(props) =>
            'url(' + (props?.leftSide?.backgroundImage?.file?.url || '') + ')'};
          background-size: cover;
          background-repeat: no-repeat;
          padding: 44px 50px;
          position: relative;

          .section-demo-hero--left-title {
            font-size: var(--font-size-44);
            line-height: 58px;
            letter-spacing: var(--letter-spacing--0-04);
            color: var(--white);
            max-width: 434px;
          }

          .section-demo-hero--form-left-bottom {
            position: absolute;
            bottom: 44px;
          }
        }
        .section-demo-hero--form-right-side {
          width: 50%;

          .section-demo-hero--signup-form {
            width: 100%;
            padding: 44px 50px;
          }
        }
      }

      .section-demo-hero--form-section-footer {
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-600);
        line-height: 26px;
        text-align: center;
        color: var(--darkest);
        margin-top: 42px;
      }
    }

    .section-demo-hero--bottom-side {
      width: 100%;

      .simple-social-proof-section--container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .section-demo-hero--animated-trustbar-container {
        height: 70px;
        .section-demo-hero--animated-trustbar {
          position: relative;
          margin: 0;
          width: 100%;
          height: 100%;
          animation: ani 60s linear infinite;

          @media (max-width: 756px) {
            animation: ani 15s linear infinite;
          }
        }
        @keyframes ani {
          0% {
            background-position: 100vw 0;
          }
          100% {
            background-position: 0 0;
          }
        }

        .section-demo-hero--animated-trustbar::before,
        .section-demo-hero--animated-trustbar::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(90deg, var(--white), transparent);
          width: 50px;
        }

        .section-demo-hero--animated-trustbar::after {
          left: auto;
          right: 0;
          background: linear-gradient(-90deg, var(--white), transparent);
        }
      }

      .section-demo-hero--trusted-content {
        margin-top: 84px;
        text-align: center;

        .section-demo-hero--trusted-title {
          font-size: var(--font-size-44);
          line-height: var(--line-height-110);
          text-align: center;
          letter-spacing: var(--letter-spacing--0-04);
          color: var(--darkest);
        }
      }

      .section-demo-hero--trusted-logo-content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        .section-demo-hero--trusted-logo-item {
          width: 160px;
          margin: 0 15px;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 991px) {
    background: ${(props) => props.background};

    .section-demo-hero--container {
    }
  }

  @media (max-width: 864px) {
    .section-demo-hero--wrapper {
      height: 613px;
    }

    .section-demo-hero--container {
      .section-demo-hero--top-side {
        .section-demo-hero--logo-wrapper {
          a {
            & > svg {
              width: 161px;
            }
          }
        }

        .section-demo-hero--text-content {
          padding-right: 0;
          margin-top: 55px;

          .section-demo-hero--description {
            font-size: var(--font-size-16);
          }
        }

        .section-demo-hero--form-section {
          .section-demo-hero--form-left-side {
            padding: 26px 26px;

            .section-demo-hero--left-title {
              font-size: var(--font-size-28);
              line-height: 34px;
              max-width: 257px;
            }

            .section-demo-hero--form-left-bottom {
              bottom: 27px;
            }
          }
          .section-demo-hero--form-right-side {
            width: 50%;

            .section-demo-hero--signup-form {
              padding: 20px 22px;

              .signup-form--title {
                font-family: var(--font-family-heading);
                font-size: var(--font-size-22);
                line-height: 25px;
              }

              .text-field-input {
                height: 30px !important;
                font-size: var(--font-size-13) !important;
                padding-left: 16px;
              }

              button {
                font-size: var(--font-size-13) !important;
                line-height: 12px;
                padding: 13px;
              }
            }
          }
        }

        .section-demo-hero--form-section-footer {
          font-size: var(--font-size-13);
          line-height: 26px;
          margin-top: 34px;
        }
      }

      .section-demo-hero--bottom-side {
        .section-demo-hero--trusted-content {
          margin-top: 70px;

          .section-demo-hero--trusted-title {
            font-size: var(--font-size-28);
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-demo-hero--wrapper {
      height: 769px;
    }

    .section-demo-hero--container {
      padding: 48px 20px;

      .section-demo-hero--top-side {
        .section-demo-hero--logo-wrapper {
          a {
            & > svg {
              width: 161px;
            }
          }
        }

        .section-demo-hero--text-content {
          padding-right: 0;
          margin-top: 40px;

          .section-demo-hero--description {
            font-size: var(--font-size-20);
            line-height: 28px;
            margin-top: 20px;
          }
        }

        .section-demo-hero--form-section {
          flex-direction: column;
          margin-top: 40px;

          .section-demo-hero--form-left-side {
            width: 100%;
            padding: 20px 23px;
            height: 460px;

            .section-demo-hero--left-title {
              font-size: var(--font-size-22);
              line-height: 32px;
              max-width: 232px;
            }

            .section-demo-hero--form-left-bottom {
              bottom: 24px;
            }
          }
          .section-demo-hero--form-right-side {
            width: 100%;

            .section-demo-hero--signup-form {
              padding: 20px 23px;

              .signup-form--title {
                font-family: var(--font-family-heading);
                font-size: var(--font-size-20);
                line-height: 26px;
              }

              .text-field-input {
                height: 45px !important;
                font-size: var(--font-size-16) !important;
                padding-left: 18px;
              }

              button {
                font-size: var(--font-size-16) !important;
                line-height: 12px;
                padding: 13px;
              }
            }
          }
        }

        .section-demo-hero--form-section-footer {
          font-size: var(--font-size-13);
          line-height: 26px;
          margin-top: 27px;
        }
      }

      .section-demo-hero--bottom-side {
        .section-demo-hero--trusted-content {
          margin-top: 70px;
        }

        .section-demo-hero--trusted-logo-content {
          flex-direction: column;

          .section-demo-hero--trusted-logo-item {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
`;
