import React from 'react';
import PropTypes from 'prop-types';
import HyperLink from 'components/Core/HyperLink';
import isNil from 'utils/isNil';
import { colorTypeHelper } from 'utils/colorUtility';
import WebsiteImage from 'components/WebsiteImage';
import MarketoForm from 'components/Form/MarketoForm';
import SimpleSocialProof from 'components/Sections/SocialProofSection/Simple';
import { STLogo } from 'components/Brand/Logos/STLogo';
import useAnimatedTrustbar from 'hooks/useAnimatedTrustbar';
import { SectionDemoHero1 } from './styles';

const DemoHeroSection1 = ({
  backgroundColor,
  title,
  description,
  leftSide,
  trustedSection,
  demoForm,
  initEmailValue,
}) => {
  const animatedTrustbar = useAnimatedTrustbar();
  const bgColor = colorTypeHelper[backgroundColor];

  return (
    <SectionDemoHero1 backgroundColor={bgColor} leftSide={leftSide}>
      <div className="section-demo-hero--wrapper">
        <div className="section-demo-hero--container">
          <div className="section-demo-hero--top-side">
            <div className="section-demo-hero--logo-wrapper">
              <HyperLink href="/">
                <STLogo />
              </HyperLink>
            </div>
            <div className="section-demo-hero--text-content">
              <h2 className="section-demo-hero--title">{title}</h2>
              <p className="section-demo-hero--description">{description}</p>
            </div>
            <div className="section-demo-hero--form-section">
              <div className="section-demo-hero--form-left-side">
                <h2 className="section-demo-hero--left-title">{leftSide.title}</h2>
                <SimpleSocialProof theme="light" />
              </div>

              <div className="section-demo-hero--form-right-side">
                <MarketoForm
                  className="section-demo-hero--signup-form"
                  dataPosition="Hero"
                  id="demo-hero--form"
                  initEmailValue={initEmailValue}
                  inlineMarketingForm={demoForm}
                  shadow={false}
                />
              </div>
            </div>

            <p className="section-demo-hero--form-section-footer">
              By submitting this form, I confirm that I have read and agree to the{' '}
              <HyperLink href="/legal/privacy-policy">Privacy Policy.</HyperLink>
            </p>
          </div>
          <div className="section-demo-hero--bottom-side">
            <SimpleSocialProof theme="dark" />
            {trustedSection && (
              <div className="section-demo-hero--trusted-content">
                <h2 className="section-demo-hero--trusted-title">{trustedSection.title}</h2>
              </div>
            )}
            {animatedTrustbar ? (
              <div className="section-demo-hero--animated-trustbar-container">
                <div
                  style={{
                    background: `url(${animatedTrustbar.image.file.url}) left center/auto 70px repeat-x`,
                  }}
                  className="section-demo-hero--animated-trustbar"
                ></div>
              </div>
            ) : trustedSection ? (
              <div className="section-demo-hero--trusted-logo-content">
                {!isNil(trustedSection.imgs) &&
                  trustedSection.imgs.map((item, index) => {
                    return (
                      <div className="section-demo-hero--trusted-logo-item" key={index}>
                        <WebsiteImage loading="eager" src={item} />
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </SectionDemoHero1>
  );
};

DemoHeroSection1.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  formTitle: PropTypes.string,
  initEmailValue: PropTypes.string,
};

export default DemoHeroSection1;
