import React from 'react';
import isNil from 'utils/isNil';
import PropTypes from 'prop-types';
import HyperLink from 'components/Core/HyperLink';
import WebsiteImage from 'components/WebsiteImage';
import MarketoForm from 'components/Form/MarketoForm';
import SimpleSocialProof from 'components/Sections/SocialProofSection/Simple';
import { STLogo } from 'components/Brand/Logos/STLogo';
import { STIcon } from 'components/Brand/Icons/STIcon';
import useAnimatedTrustbar from 'hooks/useAnimatedTrustbar';

import { SectionDemoHero } from './styles';

const DemoHeroSection = ({
  title,
  description,
  advantages,
  trustedSection,
  demoForm,
  initEmailValue,
  children,
}) => {
  const animatedTrustbar = useAnimatedTrustbar();

  return (
    <SectionDemoHero>
      <div className="section-demo-hero--container">
        <div className="section-demo-hero--left-side">
          <div className="section-demo-hero--logo-wrapper">
            <HyperLink href="/">
              <STLogo variation="black" />
            </HyperLink>
          </div>
          <div className="section-demo-hero--text-content">
            <h1 className="section-demo-hero--title font-h2">{title}</h1>
            <p className="section-demo-hero--description font-lg">{description}</p>
            <div className="section-demo-hero--advantage-container">
              {!isNil(advantages) &&
                advantages.map((item, index) => {
                  return (
                    <div className="section-demo-hero--advantage-item" key={index}>
                      <STIcon
                        className="section-demo-hero--advantage-item-icon"
                        type={'icon-success'}
                      />
                      <p className="section-demo-hero--advantage-item-title font-h5">{item}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <SimpleSocialProof theme="dark" />
          <hr className="section-demo-hero--separator" />
          {trustedSection && (
            <div className="section-demo-hero--trusted-content">
              <h2 className="section-demo-hero--trusted-title font-h3">{trustedSection.title}</h2>
            </div>
          )}
          {animatedTrustbar ? (
            <div className="section-demo-hero--animated-trustbar-container">
              <div
                style={{
                  background: `url(${animatedTrustbar.image.file.url}) left center/auto 70px repeat-x`,
                }}
                className="section-demo-hero--animated-trustbar"
              ></div>
            </div>
          ) : trustedSection ? (
            <div className="section-demo-hero--trusted-logo-content">
              {!isNil(trustedSection.imgs) &&
                trustedSection.imgs.map((item, index) => {
                  return (
                    <div className="section-demo-hero--trusted-logo-item" key={index}>
                      <WebsiteImage loading="eager" image={item} />
                    </div>
                  );
                })}
            </div>
          ) : null}
        </div>
        <div className="section-demo-hero--right-side">
          {children || (
            <MarketoForm
              backgroundColor="#00A9F7"
              className="section-demo-hero--signup-form"
              dataPosition="Hero"
              id="demo-hero--form"
              initEmailValue={initEmailValue}
              inlineMarketingForm={demoForm}
              style={{ boxShadow: '0px 30px 60px rgba(11, 26, 33, 0.15)' }}
              titleColor="white"
            />
          )}
        </div>
      </div>
      <div className="section-demo-hero--common-container">
        <hr className="section-demo-hero--separator" />
        {trustedSection && (
          <div className="section-demo-hero--trusted-content">
            <h2 className="section-demo-hero--trusted-title">{trustedSection.title}</h2>
          </div>
        )}
        {animatedTrustbar ? (
          <div className="section-demo-hero--animated-trustbar-container">
            <div
              style={{
                background: `url(${animatedTrustbar.image.file.url}) left center/auto 70px repeat-x`,
              }}
              className="section-demo-hero--animated-trustbar"
            ></div>
          </div>
        ) : trustedSection ? (
          <div className="section-demo-hero--trusted-logo-content">
            {!isNil(trustedSection.imgs) &&
              trustedSection.imgs.map((item, index) => {
                return (
                  <div className="section-demo-hero--trusted-logo-item" key={index}>
                    <WebsiteImage loading="eager" image={item} />
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
    </SectionDemoHero>
  );
};

DemoHeroSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  formTitle: PropTypes.string,
  initEmailValue: PropTypes.string,
};

export default DemoHeroSection;
